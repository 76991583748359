// Variable overrides

$white: #ffffff;
$gray-lightest: hsl(0, 0%, 97%);
$gray-lighter: hsl(0, 0%, 88%);
$gray-light: hsl(0, 0%, 69%);
$gray: hsl(0, 0%, 38%);
$gray-dark: hsl(0, 0%, 32%);
$gray-darker: hsl(0, 0%, 23%);
$black: #222222;

$orange-light: #ffb883;
$orange: #e97231;
$orange-dark: #73300c;

$purple-light: #c9bdd9;
$purple: #a798d1;
$purple-dark: #705090;

$blue-light: #b4e1fa;
$blue: #006fbb;
$blue-dark: #084e8a;
$blue-primary: #0a0f36;

$cyan-light: #b3ecff;
$cyan: #40c3f7;
$cyan-dark: #0b69a3;
$gc-cyan: #B5E3EB;

$green-light: #c6f7e2;
$green: #157d64;
$green-dark: #004d3f;

$pink: #b65485;
$pink-dark: #9d4571;

$yellow-light: #fcf1cd;
$yellow: #eec200;
$yellow-dark: #8a6116;
$gc-yellow: #FBE8AA;

$red-light: #fead9a;
$red: #de3618;
$red-dark: #bf0711;

$gc-darkblue: #282C30;
$gc-orangepink: #FF6F79;

$theme-colors: ();
$primary: $gc-orangepink;
$secondary: $cyan;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red;
$light: $gray-lighter;
$dark: $gray-darker;

$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "pink": $pink
  ),
  $theme-colors
);


$font-family-sans-serif: "Graphik"