// Here you can add other styles
.sidebar .sidebar-minimizer {
    background-color: $gc-darkblue;
  }
  
  .sidebar-minimizer::before {
    background-color: $gc-darkblue;
  }

// These two remove random bullets
// when putting items into the footer
.c-sidebar-footer .c-sidebar-nav-title {
  list-style-type:none;
}
.c-sidebar-footer .nav-item {
  list-style-type:none;
}

// The logo SVG is unpadded, so need to sort that
.gc-pad-logo {
  padding: 6px;
}

// Increase width of the dropdown tasks menu
// (THe notification one seems to grow by itself)
.c-header-nav-item .dropdown-menu {
  min-width: 300px;
}

// Provide a grey background to offset white
// text over graphics
.grey-background-centre {
  background-color: rgba(48,48,48,.75);
  text-align: center;
  border-radius: 0.3rem;
  padding-bottom: 6px;
}

.grey-background-left {
  background-color: rgba(48,48,48,.75);
  border-radius: 0.3rem ;
  padding-left: 20px;
  padding-right: 10px;
}

.grey-background-only {
  border-radius: 0.3rem ;
  background-color: rgba(48,48,48,.75) !important;
}

.no-background-border-only {
  background-color: transparent !important;
  border-color: transparent !important;
}

// Override some button colours - it uses
// some of the other palettes by default which
// can look odd
.btn-primary {
  color: black !important;
}

// Active items on the sidebar in dark theme it isn't
// so obvious they are selected
.c-sidebar-nav-link.c-active {
  background-color: $cyan-dark !important;

}

// For showing locked cards
.locked-card {
  opacity: 0.25;
}

.locked-card-lock {
  position: absolute;
  top: calc(50% - 64px);
  left: calc(50% - 64px);
  opacity: 1;
}

// A Poplar background image
.gc-background {
  background-image: url("../assets/PoplarGraphic.png"); 
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: black;
}

// Basetable for dark mode
.BaseTable__header-row {
  background-color: $cyan-dark;
  border-bottom: $gc-darkblue;
}

.BaseTable__row {
  background-color: $gc-darkblue;
  border-bottom: 1px solid #566573;
  &:hover,
  &--hovered {
    background-color: #2E4053;
  }
}

.BaseTable__table-main {
  outline-color: $cyan-dark;
}

.BaseTable {
  box-shadow: none;
  background-color: black;
}

.BaseTable__table {
  background-color: $gc-darkblue;
}

.BaseTable__header-cell {
  justify-content: center;
}

.BaseTable__row-cell {
  justify-content: center;
}

// Smaller fonts for the experiences
.display-5 {
  font-size: 2.0rem ;
  font-weight: $display4-weight;
  line-height: $display-line-height;
}

// Litegraph stuff
.litegraph-editor {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  background-color: #333;
  color: #eee;
  font: 14px Tahoma;

  position: relative;
}

.litegraph-editor h1 {
  font-family: "Metro Light", Tahoma;
  color: #ddd;
  font-size: 28px;
  padding-left: 10px;
  /*text-shadow: 0 1px 1px #333, 0 -1px 1px #777;*/
  margin: 0;
  font-weight: normal;
}

.litegraph-editor h1 span {
  font-family: "Arial";
  font-size: 14px;
  font-weight: normal;
  color: #aaa;
}

.litegraph-editor h2 {
  font-family: "Metro Light";
  padding: 5px;
  margin-left: 10px;
}

.litegraph-editor * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.litegraph-editor .content {
  position: relative;
  width: 100%;
  height: calc(100% - 80px);
  background-color: #1a1a1a;
}

.litegraph-editor .header,
.litegraph-editor .footer {
  position: relative;
  height: 40px;
  background-color: #333;
  /*border-radius: 10px 10px 0 0;*/
}

.litegraph-editor .tools,
.litegraph-editor .tools-left,
.litegraph-editor .tools-right {
  position: absolute;
  top: 2px;
  right: 0px;
  vertical-align: top;

  margin: 2px 5px 0 0px;
}

.litegraph-editor .tools-left {
  right: auto;
  left: 4px;
}

.litegraph-editor .footer {
  height: 40px;
  position: relative;
  /*border-radius: 0 0 10px 10px;*/
}

.litegraph-editor .miniwindow {
  background-color: #333;
  border: 1px solid #111;
}

.litegraph-editor .miniwindow .corner-button {
  position: absolute;
  top: 2px;
  right: 2px;
  font-family: "Tahoma";
  font-size: 14px;
  color: #aaa;
  cursor: pointer;
}

/* BUTTONS **********************/

.litegraph-editor .btn {
  /*font-family: "Metro Light";*/
  color: #ccc;
  font-size: 20px;
  min-width: 30px;
  /*border-radius: 0.3em;*/
  border: 0 solid #666;
  background-color: #3f3f3f;
  /*box-shadow: 0 0 3px black;*/
  padding: 4px 10px;
  cursor: pointer;
  transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 0.4s;
}

.litegraph-editor button:hover {
  background-color: #999;
  color: #fff;
  transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 0.4s;
}

.litegraph-editor button:active {
  background-color: white;
}

.litegraph-editor button.fixed {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1.2em;
}

.litegraph-editor button img {
  margin: -4px;
  vertical-align: top;
  opacity: 0.8;
  transition: all 1s;
}

.litegraph-editor button:hover img {
  opacity: 1;
}

.litegraph-editor .header button {
  height: 32px;
  vertical-align: top;
}

.litegraph-editor .footer button {
  /*font-size: 16px;*/
}

.litegraph-editor .toolbar-widget {
  display: inline-block;
}

.litegraph-editor .editor-area {
  width: 100%;
  height: 100%;
}

/* METER *********************/

.litegraph-editor .loadmeter {
  font-family: "Tahoma";
  color: #aaa;
  font-size: 12px;
  border-radius: 2px;
  width: 130px;
  vertical-align: top;
}

.litegraph-editor .strong {
  vertical-align: top;
  padding: 3px;
  width: 30px;
  display: inline-block;
  line-height: 8px;
}

.litegraph-editor textarea.code, .litegraph-editor div.code {
height: 100%;
width: 100%;
background-color: black;
padding: 4px;
font: 16px monospace;
overflow: auto;
resize: none;
outline: none;
  color: #DDD;
}

.litegraph-editor .codeflask {
background-color: #2a2a2a;
}

.litegraph-editor .codeflask textarea {
opacity: 0;
}

// Make text sizes responsive
@media screen and (max-width: 1560px) {
  .display-4 {
     font-size:24px !important;
  }
  .display-5 {
    font-size:20px !important;
 }
 .display-6 {
  font-size:18px !important;
}
.lead {
    font-size:12px !important;
  }
}

